
import { Watch, Emit, Prop, Component, Vue, InjectReactive, Ref, PropSync } from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import { brandMiniConfig, saveBrandMiniConfig } from "@/api/prescriptionDb";

import { Form } from "element-ui";

@Component({
    components: {UploadImg}
})
export default class MiniSetting extends Vue {
    @Prop() readonly brandInfo!: any;
    @PropSync("dialogVisible") dialogVisibleSync!: Boolean; 
    @Ref("formRef") formRef!: Form;

    form = {
        id: "",
        appId: "",
        appSecret: "",
        thirdMiniName: "",
        pulseTakingUrl: "",
        status: true
    }

    rules = {
        appId: [{required: true, message: "请输入Appid" }],
        appSecret: [{required: true, message: "请输入AppSecret" }],
        thirdMiniName: [{required: true, message: "请输入小程序名称" }],
        pulseTakingUrl: [{required: true, message: "请输入脉诊链接" }],
        miniLogo: [{required: true, message: "请上传小程序logo" }],
    }

    saveLoading = false;

    @Watch("brandInfo", { immediate: true, deep: true })
    initForm() {
        if (!this.brandInfo?.id) return;
        brandMiniConfig(this.brandInfo.id).then((res) => {
            const config = res.data || {};
            if (Reflect.ownKeys(config).length <= 0) return;
            Object.assign(this.form, { ...config, status: Boolean(config.status) })
        })
    }

    handleClose() {
        this.formRef.resetFields();
        this.form.id = "";
        this.dialogVisibleSync = false;
    }

    save() {
        this.formRef.validate().then(() => {
            console.log(this.form);
            const params = { ...this.form, status: Number(this.form.status), merchantId: this.brandInfo.id }
            this.saveLoading = true;
            saveBrandMiniConfig(params).then(() => {
                this.$message.success("操作成功");
                this.handleClose();
                this.syncList();
            }).finally(() => {
                this.saveLoading = false;
            })
        })
    }

    @Emit("syncList")
    syncList() {
        return true;
    }
}
